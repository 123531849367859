




































































































































import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import rules from "@/modules/rules";
import { Level } from "@/store/interfaces/Level";
import { MediaType } from "@/store/interfaces/MediaType";
import Category from "@/store/models/Category";
import Tag from "@/store/models/Tag";
import Vue from "vue";

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    categoryKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    processedTags: {
      get(): string[] {
        console.log(this.tags);
        const array = [];
        for (const tag of this.tags) {
          if (typeof tag == "object") {
            array.push((tag as Tag).value);
          } else {
            array.push(tag);
          }
        }
        return array;
      },
      set(value: string[]) {
        this.tags = value;
        console.log(this.tags);
      },
    },
    tagsList() {
      const array = [];
      for (const tag of this.$store.state.tags as Tag[]) {
        array.push(tag.value);
      }
      return array;
    },
    thumbnailFile: {
      get(): File | null {
        return this.thumbnail as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.thumbnail = value;
              this.thumbnailSrc = URL.createObjectURL(value);
              this.thumbnailChanged = true;
            } else {
              this.thumbnail = null;
              this.thumbnailSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.thumbnail = value;
            this.thumbnailSrc = base64Images.thumbnailPlaceholder;
            this.thumbnailChanged = true;
          }
        } catch (error: any) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.category = null;
          this.tags = [];
          this.categoryObjectLoaded = false;
          this.categoryObjectEdited = false;
          this.thumbnailSrc = base64Images.thumbnailPlaceholder;
          this.tagSearch = null;
          (this.$refs.editCategoryForm as any).resetValidation();
          this.$emit("close");
          setTimeout(() => {
            this.thumbnailFile = null;
            this.thumbnailChanged = false;
            this.categoryLoading = true;
          }, 300);
        }
      },
    },
    parsedType: {
      get(): string {
        if (this.categoryKey) {
          switch (this.categoryKey) {
            case "move":
              return "Bouger";
            case "health":
              return "Santé";
            case "blog":
              return "Blog";
            default:
              return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      categoryLoading: true,
      deleteDialog: false,
      deleteLoading: false,
      updateLoading: false,
      thumbnailSrc: base64Images.thumbnailPlaceholder,
      thumbnailChanged: false,
      thumbnail: null as File | null,
      category: null as null | Category,
      tags: [] as Array<Tag | string>,
      tagSearch: null as string | null,
      categoryObjectLoaded: false,
      categoryObjectEdited: false,
      rules: rules(this),
      valid: false,
    };
  },
  watch: {
    async id() {
      // If ID, then we fetch the details
      if (this.id && this.categoryKey) {
        // Fetch video object
        this.getCategory();
      }
    },
    category: {
      deep: true,
      handler(newValue, oldValue) {
        // If the video changes while being "loaded", it means that the admin has edited it
        // we thus need to set it as "edited to allow the button to be clickable
        if (oldValue && this.categoryObjectLoaded) {
          this.categoryObjectEdited = true;
        }
      },
    },
  },
  methods: {
    async deleteCategory(id: number) {
      this.deleteLoading = true;
      await Request.shared.delete(`category/${this.categoryKey}/${id}`);
      await this.$store.dispatch("getCategories", this.categoryKey);
      this.deleteLoading = false;
      this.showDialog = false;
    },
    async updateCategory() {
      try {
        this.updateLoading = true;
        if (this.category) {
          if (this.thumbnailChanged) {
            // If the user has deleted the thumbnail, we send the appropriate request (an empty thumbnail route)
            if (!this.thumbnailFile) {
              const thumbnailDeleted = await Request.shared.put(
                `category/${this.categoryKey}/${this.category.id}/coverpic`
              );
            } else {
              // The user has set a new thumbnail, we need to create the media and assign it to the video
              const mediaBody = {
                type: MediaType.cover,
                alt: this.thumbnailFile.name,
              };
              // We create the formdata
              const formData = new FormData();
              formData.append("body", JSON.stringify(mediaBody));
              formData.append("media", this.thumbnailFile);
              const media = await Request.shared.post("media", formData);
              // Once we got the media, we can assign the thumbnail to the video
              const assignedThumbnail = await Request.shared.put(
                `category/${this.categoryKey}/${this.category.id}/coverpic/${media.data.id}`
              );
            }
          }
          if (this.tags) {
            // First we remove all the tags if there are any :
            if (this.category.Tags) {
              for (const tag of this.category.Tags) {
                const deletedTag = await Request.shared.delete(
                  `category/${this.categoryKey}/${this.category.id}/tag/${tag.id}`
                );
              }
            }
            let tagFound = undefined as Tag | undefined;
            // Tag handling (we are forced to cycle through every tag)
            for (const tag of this.processedTags) {
              if (typeof tag == "object") {
                // We try to find a tag with the same name (case insensitive)
                tagFound = (this.$store.state.tags as Tag[]).find((value) => {
                  return (tag as Tag).value == value.value;
                });
              } else {
                // We try to find a tag with the same name (case insensitive)
                tagFound = (this.$store.state.tags as Tag[]).find((value) => {
                  return tag == value.value;
                });
              }

              if (tagFound) {
                // If the tag exists, we assign it
                const assignedTag = await Request.shared.post(
                  `category/${this.categoryKey}/${this.category.id}/tag/${tagFound.id}`
                );
              } else {
                // We need to create the tag first, then assign it
                const postedTag = await Request.shared.post("tag", {
                  value: tag,
                });
                const assignedTag = await Request.shared.post(
                  `category/${this.categoryKey}/${this.category.id}/tag/${postedTag.data.id}`
                );
              }
            }
          }

          // We need to get rid of these includes to prevent wrecking havoc
          delete this.category.CoverPic;

          // Update Category
          const categoryUpdated = await Request.shared.put(
            `category/${this.categoryKey}/${this.category.id}`,
            this.category
          );
        }
        this.updateLoading = false;
        this.$store.dispatch("showAlert", {
          message: "La categorie a été mise à jour",
          color: "success",
          timeout: 4000,
        });
        this.$emit("updated");
        this.categoryObjectEdited = false;
        this.thumbnailChanged = false;
      } catch (error) {
        console.error(error);
      }
    },
    selectImage() {
      (this.$refs.thumbnailSelector as any).$refs.input.click();
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.thumbnailFile = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
    async getCategory() {
      try {
        this.categoryObjectLoaded = false;
        const category = await Request.shared.get(
          `category/${this.categoryKey}/${this.id}`
        );
        const categoryObject = category.data as Category;
        this.category = categoryObject;
        this.tags = [...this.category.Tags!];
        this.categoryObjectEdited = false;
        this.categoryObjectLoaded = true;
        // If we're here, we can already show the video information
        this.categoryLoading = false;
        // If there is a thumbnail, we get the image
        if (this.category.CoverPic) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${categoryObject.CoverPic?.filename}`,
              {
                responseType: "blob",
              }
            );
            this.thumbnailSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
      } catch (error) {
        // Show alert and close
      }
    },
    tagsChanged() {
      this.categoryObjectEdited = true;
    },
  },
});
