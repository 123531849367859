























import Vue from "vue";
import CategoryDataTable from "@/components/CategoryDataTable.vue";

export default Vue.extend({
  components: {
    CategoryDataTable,
  },
  data: () => ({
    panels: [
      {
        title: "Bouger",
        categoryKey: "move",
      },
      {
        title: "Santé",
        categoryKey: "health",
      },
      {
        title: "Blog",
        categoryKey: "blog",
      },
    ],
  }),
  async beforeMount() {
    await this.$store.dispatch("getCategories", "health");
    await this.$store.dispatch("getCategories", "move");
    await this.$store.dispatch("getCategories", "blog");
    await this.$store.dispatch("getTags");
  },
});
