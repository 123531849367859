




























import Vue from "vue";
import { DataTableHeader } from "vuetify";
import AddCategoryDialog from "./AddCategoryDialog.vue";
import EditCategoryDialog from "./EditCategoryDialog.vue";

export default Vue.extend({
  components: {
    AddCategoryDialog,
    EditCategoryDialog,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    categoryKey: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    editIndex: 0,
    editDialog: false,
    addDialog: false,
    headers: [
      {
        text: "Nom",
        value: "name",
      },
      {
        text: "Editer",
        value: "tools",
        width: 50,
      },
    ] as DataTableHeader[],
  }),
  methods: {
    postUpdateCycle() {
      this.$store.dispatch("getCategories", this.categoryKey);
      this.$store.dispatch("getTags");
    },
    closeEditDialog() {
      this.editIndex = 0;
      this.editDialog = false;
    },
    openEditDialog(item: Record<string, any>) {
      this.editIndex = item.id;
      this.editDialog = true;
    },
  },
});
